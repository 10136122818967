<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto">
      <div class="d-flex flex-row">
        <div style="width: 100%" class="pl-25">
          <b-button
            :variant="`${!toogleFormCreateSKP ? 'outline-' : ''}danger`"
            block
            @click="toogleFormCreateSKP = !toogleFormCreateSKP"
          >
            {{ toogleFormCreateSKP ? "Batalkan " : "Tambah Berkas SKP" }}
          </b-button>
        </div>
        <!-- <div style="width: 50%" class="pl-25">
          <b-button
            variant="outline-danger"
            block
            @click="$router.push('/krip/jadwal')"
          >
            Lihat Jadwal
          </b-button>
        </div> -->
      </div>

      <b-form v-if="toogleFormCreateSKP" class="mt-1" @submit.prevent>
        <validation-observer ref="formCreateSKP">
          <b-form-group label="Berkas" label-for="berkas">
            <validation-provider
              #default="{ errors }"
              name="berkas"
              rules="required"
            >
              <b-form-file
                id="berkas"
                v-model="skp_forms.skp_file"
                :state="errors.length > 0 ? false : null"
                name="file"
                @change="onChangeBerkasSKP($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-img
              v-if="skp_forms.skp_file"
              fluid
              center
              :src="skp_forms.skp_file"
              alt="fileBerkasSKP"
              class="mt-1"
              style="max-height: 250px"
            />
          </b-form-group>
          <b-form-group label="Jenis SKP" label-for="skpjeni_id">
            <validation-provider
              #default="{ errors }"
              name="Jenis SKP"
              rules="required"
            >
              <b-form-input
                id="skp_jenis"
                v-model="skp_forms.skpjeni_id"
                :state="errors.length > 0 ? false : null"
                name="skpjeni_id"
                type="text"
                placeholder="isi dengan angka..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Nomor SKP" label-for="skp_no">
            <validation-provider
              #default="{ errors }"
              name="Nomor SKP"
              rules="required"
            >
              <b-form-input
                id="skp_no"
                v-model="skp_forms.skp_no"
                :state="errors.length > 0 ? false : null"
                name="skp_no"
                type="text"
                placeholder="isi nomor skp anda..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Jumlah SKP" label-for="skp_jumlah">
            <validation-provider
              #default="{ errors }"
              name="Jumlah SKP"
              rules="required"
            >
              <b-form-input
                id="skp_jumlah"
                v-model="skp_forms.skp_jumlah"
                :state="errors.length > 0 ? false : null"
                name="skp_jumlah"
                type="text"
                placeholder="isi jumlah skp anda..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="outline-danger"
            block
            @click="validationFormSKP"
          >
            Simpan
          </b-button>
        </validation-observer>
        <hr />
      </b-form>
      <div class="mt-1">
        <b-card
          v-for="item in skp.data"
          :key="item.id"
          class="shadow-none border p-1 mb-1"
          no-body
        >
          <div class="d-flex pb-1 border-bottom">
            <div>
              <div class="font-weight-bold">#SKP-BERKAS-{{ item.id }}</div>
              <div class="p-1">
                <table>
                  <tbody>
                    <tr>
                      <td>Nomor SKP</td>
                      <td class="font-weight-bold">: {{ item.skp_no }}</td>
                    </tr>
                    <tr>
                      <td>Jumlah SKP</td>
                      <td class="font-weight-bold">: {{ item.skp_jumlah }}</td>
                    </tr>
                    <tr>
                      <td>Jenis SKP</td>
                      <td class="font-weight-bold">: {{ item.skpjeni_id }}</td>
                    </tr>
                    <tr>
                      <td>Dibuat</td>
                      <td class="font-weight-bold">: {{ item.created_at }}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <b-badge
                        v-if="item.skp_verified"
                        variant="light-success font-weight–light mt-25"
                      >
                        {{ "sudah terverifikasi" }}
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-danger font-weight–light mt-25"
                      >
                        {{ "belum terverifikasi" }}
                      </b-badge>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="ml-auto pointer text-danger"
              @click="hapusBerkasSKP(item.id)"
            >
              <feather-icon icon="TrashIcon" size="16" class="align-middle" />
            </div>
          </div>
          <div class="pb-1 pt-1">
            <b-img
              v-if="!isPDF(item)"
              :src="getSKPFile(item)"
              fluid
              class="mb-25"
            />
            <div
              v-else
              class="text-primary text-underline pointer"
              @click="viewPdf(getSKPFile(item))"
            >
              <u>Lihat Berkas SKP</u>
            </div>
          </div>
        </b-card>

        <div v-if="skp.isLoading" class="d-flex justify-content-center mb-1">
          <b-spinner label="Loading..." variant="danger" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BButton,
  BCard,
  BImg,
  BSpinner,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  // BBadge,
} from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
// import { required, email } from '@validations'
import apis from "@/api";
import storage from "@/helpers/storage";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BButton,
    BCard,
    BImg,
    BBadge,
    BSpinner,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  data() {
    return {
      required,
      examplePicStudiKasus: require("@/assets/images/pages/Studi_Kasus_Contoh.jpeg"),
      skp: {
        data: null,
        isLoading: false,
        nextPageUrl: null,
      },
      tempFileBerkas: null,
      fileBerkasSKP: null,
      toogleFormCreateSKP: false,
      skp_forms: {
        skpjeni_id: "",
        skp_no: "",
        skp_jumlah: "",
        skp_file: "",
      },
    };
  },
  computed: {
    // https://www.idijakpus.or.id/uploads/krip/krip_file/1473/A2.png
    getSKPFile() {
      // DEPRECATED code below
      // return (item) => {
      //   if (item.skp_file) {
      //     if (!item.skp_file.includes("https")) {
      //       return `https://www.idijakpus.or.id/uploads/krip/skp_file/${item.id}/${item.skp_file}`;
      //     }
      //     return item.skp_file;
      //   }
      //   return null;
      // };
      //new code
      return (item) => {
        if (item.skp_file) {
          if (item.skp_file.includes("https")) {
            return item.skp_file;
          }
        }
        return null;
      };
    },
    isPDF() {
      return (item) => {
        if (item.skp_file?.includes(".pdf")) {
          return true;
        }
        return false;
      };
    },
  },
  mounted() {
    document
      .getElementsByClassName("app-wrapper")[0]
      .addEventListener("scroll", this.scrollCallback);
  },
  created() {
    this.fetchSKP();
  },
  methods: {
    viewPdf(url) {
      storage.setStorage("pdfUrl", url);
      this.$router.push({ path: "/pdfview" });
    },
    scrollCallback() {
      const element = document.getElementsByClassName("app-wrapper")[0];
      const { scrollTop } = element;
      const { scrollHeight } = element;
      const { clientHeight } = element;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (!this.skp.isLoading && this.skp.nextPageUrl) {
          this.fetchSKP(this.skp.nextPageUrl);
        }
      }
    },
    onChangeBerkasSKP(e) {
      const { files } = e.target;
      if (files.length) {
        this.createBerkasSKP(files[0], (result) => {
          this.skp_forms.skp_file = result;
        });
      }
    },
    createBerkasSKP(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    validationFormSKP() {
      this.$refs.formCreateSKP.validate().then((success) => {
        if (success) {
          this.simpanBerkasSKP();
        }
      });
    },
    fetchSKP(url) {
      this.skp.isLoading = true;
      apis.profile
        .getSkp(url)
        .then(({ data }) => {
          if (url) {
            this.skp.data = this.skp.data.concat(data.data);
          } else {
            this.skp.data = data.data;
          }
          this.skp.nextPageUrl = data.next_page_url;
        })
        .finally(() => {
          this.skp.isLoading = false;
        });
    },
    simpanBerkasSKP() {
      this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
      apis.profile
        .createSkp({
          skpjeni_id: this.skp_forms.skpjeni_id,
          skp_no: this.skp_forms.skp_no,
          skp_jumlah: this.skp_forms.skp_jumlah,
          skp_file: this.skp_forms.skp_file,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Berhasil upload berkas SKP",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.toogleFormCreateSKP = false;
          this.fetchSKP();
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "Gagal upload berkas SKP, silahkan coba lagi nanti"
          );
        })
        .finally(() => {
          this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
        });
    },
    hapusBerkasSKP(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Berkas yang sudah dihapus, tidak bisa dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
            return apis.profile.deleteSkp(id);
          }
          return false;
        })
        .then((result) => {
          if (result) {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
            this.fetchSKP();
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Berhasil menghapus berkas",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "Gagal hapus berkas, silahkan coba lagi nanti"
          );
        });
    },
  },
};
</script>
